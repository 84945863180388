@import './sass/bulma-custom.scss';

.navbar-brand img {
    margin-right: .5rem;
}

.cards .card {
    margin-bottom: 1rem;
}

#switch {
    max-width: 960px;
}

.has-left-margin {
    margin-left: .5rem;
}

.media + .media {
	border-top: none;
}

.is-none {
	list-style: none;
}

.logo {
	color: #ff8f59;
	font-weight: bold;
}

.highlight {
	color: #5e6ab8;
	font-weight: 600;
}

section a {
	font-weight: 600;
}

a.button {
	font-weight: normal;
}

.caption {
	text-align: center;
}

.table-image {
	width: 15em;
	margin: auto !important;
}

.table-heading-step-1 th {
	background-color: #e5f5f8;
}

.table-heading-step-2 th {
	background-color: #fff1ee;
}

.table-heading-step-1, .table-heading-step-2 {
	font-size: 1.25em;
}

.center, .table-heading th, .table-heading-step-1 th, .table-heading-step-2 th {
	text-align: center !important;
}

.image-center {
	margin: auto !important;
}

.image-center img {
	border-radius: 50%;
}