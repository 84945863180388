@charset "utf-8";

// primary colors
$calypso: #00a4bd;
$lorax: #ff7a59;

// secondary colors
$oz: #00bda5;
$thunderdome: #6a78d1;
$candy-apple: #f2545b;
$norman: #f2547d;
$sorbet: #ff8f59;
$marigold: #f5c26b;

// dark greys
$flat-earth: #253342;
$pantera: #2d3e50;
$obsidian: #33475b;
$heffalump: #425b76;
$slinky: #516f90;
$eerie: #7c98b6;

// light greys
$flint: #99acc2;
$battleship: #cbd6e2;
$great-white: #dfe3eb;
$koala: #eaf0f6;
$gypsum: #f5f8fa;
$olaf: #ffffff;

// fonts
@font-face {
  font-family: "Avenir Next";
  src: url(./fonts/AvenirNext-Medium.woff2) format("woff2"),
    url(./fonts/AvenirNext-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next Demi";
  src: url(./fonts/AvenirNext-DemiBold.woff2) format("woff2"),
    url(./fonts/AvenirNext-DemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next";
  src: url(./fonts/AvenirNext-Regular.woff2) format("woff2"),
    url(./fonts/AvenirNext-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next";
  src: url(./fonts/AvenirNext-Bold.woff2) format("woff2"),
    url(./fonts/AvenirNext-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

// customizations
$primary: $lorax;
$link: $calypso;
$info: $thunderdome;
$success: $oz;
$warning: $marigold;
$danger: $candy-apple;
$dark: $flat-earth;
$text: $obsidian;
$family-sans-serif: "Avenir Next", sans-serif;

@import 'node_modules/bulma/bulma.sass';
@import '../../node_modules/bulma-o-steps/bulma-steps.sass';
